






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import { BopModelEnum, UserRoleEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";

@Component({
  components: {},
})
export default class EditField extends BaseField {
  @Prop({ required: true }) public props!: any;
  private isBeingEdited: Boolean = false;
  private previous: any = "";

  private width: number = 0;

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }
  private get isNumber(): boolean {
    return this.props.rowField.isNumber ?? false;
  }
  private get specialClass(): string {
    if (
      this.props.rowField.property === "name" &&
      this.props.rowField.dataClass === "bop"
    ) {
      return "bopName";
    } else {
      return "";
    }
  }

  private get format() {
    let res = this.rawData;
    if (this.props.rowField.isDate) {
      const datetoConvert = new Date(this.rawData);
      res = new Date(
        // this.rawData
        datetoConvert.getTime() - datetoConvert.getTimezoneOffset() * 60000
      )?.toLocaleTimeString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return res;
  }
  get isCalorifugeageAppareil(): boolean {
    return (
      this.$store.state.selectedBopModel &&
      this.$store.state.selectedBopModel.key ===
        BopModelEnum.CalorifugageAppareil
    );
  }
  private get isNatureTacheField(): boolean {
    return this.props.rowField.isNatureTacheField;
  }
  private get isDisabled() {
    return (
      this.isNatureTacheField &&
      ((!this.props.rowField.isMontage && !this.props.rowData.flagAction) ||
        (this.props.rowField.isMontage && !this.props.rowData.isMontageVisible))
    );
  }

  private get computedClass() {
    let res = "";
    if (this.computedCanBeEdited && !this.isDisabled) res += "field-pointer";
    else {
      res += " disabled";
    }
    if (this.rawData && this.rawData.length > 100) res += " larger";

    return res;
  }
  public edit(): void {
    //this.text = table.findByPath(this.props.rowData, this.props.rowField.property)
    if (this.computedCanBeEdited && !this.isDisabled) {
      this.isBeingEdited = !this.isBeingEdited;
      if (this.isBeingEdited) {
        this.previous = this.rawData;
        const width = (<HTMLElement>this.$refs.rawField).clientWidth;
        this.$nextTick(() => {
          (<HTMLInputElement>this.$refs.input).select();
          (<HTMLElement>this.$refs.input).style.width = `${width}px`;
        });
      } else {
        if (this.props.rowField.regex) {
          const regex: RegExp = this.props.rowField.regex;

          if (this.props.rowField.regexCondition) {
            if (this.props.rowField.regexCondition == UserRoleEnum.orderGiver) {
              if (
                this.props.rowData.keyRole &&
                this.props.rowData.keyRole == UserRoleEnum.orderGiver
              ) {
                if (!regex.test(this.rawData)) {
                  this.$notify({
                    group: "globalError",
                    type: "error",
                    title: this.$t("validation.updateFailed").toString(),
                    text: this.$t(
                      "validation.propertyIdSapNonCompliant"
                    ).toString(),
                    duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
                  });
                  this.rawData = this.previous;
                }
              }
            }
          } else if (!regex.test(this.rawData)) {
            this.$notify({
              group: "globalError",
              type: "error",
              title: this.$t("validation.updateFailed").toString(),
              text: this.$t("validation.propertyIdSapNonCompliant").toString(),
              duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
            });
            this.rawData = this.previous;
          }
        } else if (this.props.rowField.type) {
          if (this.props.rowField.type == "email") {
            if (!this.checkMail(this.rawData)) {
              this.$notify({
                group: "globalError",
                type: "error",
                title: this.$t("validation.updateFailed").toString(),
                text: this.$t("validation.propertyMailNonCompliant").toString(),
                duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
              });
              this.rawData = this.previous;
            }
          }
        } else if (this.props.rowField.length) {
          if (this.rawData.length != this.props.rowField.length) {
            this.$notify({
              group: "globalError",
              type: "error",
              title: this.$t("validation.updateFailed").toString(),
              text: this.$t("validation.propertyIdSapNonCompliant").toString(),
              duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
            });
            this.rawData = this.previous;
          }
        } else {
          (<any>this.$parent).$forceUpdate();
        }
      }
    }
  }
  private checkMail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private mounted() {
    this.width = (<HTMLElement>this.$refs.rawField).offsetWidth;
  }
}
