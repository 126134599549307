
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import table from "@/utils/table";

@Component({})
export default class BaseField extends Vue {
  @Prop({ required: true }) public props!: any;

  // Reactive data property to control the editable state
  private editableState: Boolean = true;

  get canBeEdited(): Boolean {
    return !(<any>this.$parent.$parent).notEditable && this.editableState;
  }

  get rawData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.property);
  }

  set rawData(newValue: any) {
    table.setByPath(this.props.rowData, this.props.rowField.property, newValue);
    (<any>this.$parent.$parent).modify(this.props.rowData);
  }

  get computedCanBeEdited(): Boolean {
    return !this.props.rowField.isNotEditable && this.canBeEdited;
  }

  // Method to set the editable state
  setEditableState(isEditable: Boolean) {
    this.editableState = isEditable;
  }

  // Watch the parent's notEditable state
  @Watch("$parent.$parent.notEditable", { immediate: true, deep: true })
  onParentEditableStateChange(newValue: Boolean) {
    this.editableState = !newValue;
  }

  protected getByProperty(property: string): any {
    return table.findByPath(this.props.rowData, property);
  }
}
